<template>
    <OrderForm />
</template>

<script>
import OrderForm from '@/components/Order/OrderForm'

export default {
    name: "CreateOrder",
    components: {
        OrderForm
    },
    data: () => ({
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Заказ ПО', link: '/' },
            { title: 'Перечень заказов на поставку', link: '/purchase-orders' },
            { title: 'Создание заказа на поставку' }
        ]
    })
}
</script>

<style scoped>

</style>
